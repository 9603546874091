import { useTranslation } from "react-i18next"
import Popup from "../Popup/Popup"
import { useAppSelector } from "src/redux/hooks";
import { useDispatch } from "react-redux";
import { clearAllFailedActions } from "src/redux/actions/actions.failedActions";

export interface FailedTryLaterPopupProps {
    action: {
        type: 'get' | 'add' | 'update' | 'delete' ,
        itemName: string   // e.g. 'profile', 'user', 'query', ...
    },
}

// to be used e.g. for 503 resp. from BE
const FailedTryLaterPopup = ({ action }: FailedTryLaterPopupProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const failedActions = useAppSelector(state => state.failedActions.items);
    const failedAction = failedActions.length > 0 ? failedActions[0]?.action : null;

    return (failedAction &&
        // @ts-ignore
        <Popup
            size="auto"
            blockContent={true}
            onOk={() => dispatch(clearAllFailedActions())}
        >
            <div>
                <h3>{t('error_try_later_title')}</h3>
                <p>
                    {t('error_try_later_msg')}
                </p>
                {failedAction && 
                    <table>
                        <tbody>
                            <tr>
                                <td><b>{t('action')}:</b></td>
                                <td>{failedAction.type}</td>
                            </tr>
                            <tr>
                                <td><b>{t('item name')}:</b></td>
                                <td>{failedAction.itemName}</td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
        </Popup>
    )
}

export default FailedTryLaterPopup;
import React from 'react';
import detailLayout from '../../basicStyles/detailLayout.module.scss';
import formStyles from '../../basicStyles/forms.module.scss';
import _ from 'lodash';
import DeleteRow from '../../basicElements/DeleteRow/DeleteRow';
import LoadingSpinner from '../../basicElements/LoadingSpinner/LoadingSpinner';
import APIProfiles from '../../../../API/APIProfiles';
import APIProfileGroups from '../../../../API/APIProfileGroups';
import APIChannelGroups from '../../../../API/APIChannelGroups';
import TextInput from '../../basicElements/TextInput/TextInput';
import AlertBox from '../../basicElements/AlertBox/AlertBox';
import classNames from 'classnames';
import styles from './Profile.module.scss';
import QueryInput from './QueryInput';
import LabeledSwitch from '../../basicElements/SwitchElements/LabeledSwitch';
import APIMe from '../../../../API/APIMe';
import FormFooter from '../../basicElements/FormFooter/FormFooter';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getGroups as getChannelGroups, deleteGroup } from '../../../../redux/actions/actions.channelGroups';
import Button from '../../basicElements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle, faTrash} from '@fortawesome/free-solid-svg-icons';
import Collapsible from '../../basicElements/Collapsible/Collapsible';
import LabeledSelect from '../../basicElements/LabeledSelect/LabeledSelect';
import BarChart from '../../Charts/BarChart';
import { getSchedules } from 'src/redux/actions/actions.schedules';
import { STATUS_SUCCEEDED } from 'src/redux/types/status';
import { hasContent, trackEvent } from '../../helpers';
import SelectAsync from '../../basicElements/SelectAsync/SelectAsync';
import ChannelGroup from 'src/Widgets/common/DetailViews/ChannelGroup/ChannelGroup.js'
import Search from 'src/Widgets/Search/Search';
import BackButton from '../../basicElements/BackButton/BackButton';
import LoadingIndicator from '../../basicElements/LoadingIndicator/LoadingIndicator';
import Popup from '../../basicElements/Popup/Popup';
import ReChart from '../../Charts/ReChart';
import InfoBox from '../../basicElements/InfoBox/InfoBox';
import config from 'src/config/config';
import MentionsVisualizer from 'src/Widgets/MentionsVisualizer/MentionsVisualizer';
import { addProfile, deleteProfile, updateProfile } from 'src/redux/actions/actions.profileGroups';
// import { DragDropContext } from 'react-beautiful-dnd';

/*

TODOs related to spamcheck:
    - if spam check did not run since last save (orange triangle): notify user when done? (modal as shown when multiple spam checks run?)
        but probably sufficient that user can watch icon which was just clicked to easily know when check was done (should not be orange afterwards)
    - UX: scroll to first spam failed query chart
    - tbd with TN: acceptThresholdExceedance() (rc=90071) - do we still support this? if yes: how to send it to backend ? Should we show this per query too? in v2 we sent it with save profile call
*/

/**
 * Detailview of a profile
 * Takes props:
 *  - profileId: basic profile data, only required when update=true
 *  - groupId: id of the group to be added to (default), only required when update=false
 *  - onGoBack(deleted): function that's called when back button is pressed
 *  - update - when profile is first created (and therefore not fetched)
 *  - prefill: object with profile properties - values get prefilled (to e.g. support generate profile from search)
 */

// TODO:
// sorting:
// - ensure xx does not get 'sorted away' after choosing a language ?

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.newQueriesCount = 0;   // used for assigning local ids 'client_id' (used to reference until we receive backend id when saving)
        this.state = {
            // empty profile
            profile: {
                title: '',
                queries: {},
                isSuper: false,
                isEnabled: true,
                notifyIM: true,
                notifyEmail: 0,
                profileGroupId: undefined,
                channelGroupId: null,
                shareGroups: [],
                updatedShareGroups:[],
                logos: [],
                initShareGroups:[],  // keeps track of sharegroups which are currently saved in BE - to know which ones we need to update when user clicks 'save'
                // queryTree: [],
            },
            settingsCollapsed: false,
            saveSuccessful: false,
            saveFailed: undefined,
            // NOTE: below data is for trying saveFailed without api
            // saveFailed: {
            //     status: 201, data: {
            //         spam:300,
            //         msg: 'The query exceeds the threshold for results and has not been saved. Please check your query and try to limit results to a manageable number.',
            //         stats: [
            //             {x:'2019-04-21',y:10}, {x:'2019-04-22',y:2},{x:'2019-04-23',y:1}, {x:'2019-04-24',y:2}
            //             ]
            //     }
            // },
            hoveredValue: undefined, // used to show graph tooltip on hover {x: ...,y: ...}

            profileGroups: undefined,
            channelGroups: undefined,
            edited: false,

            update: this.props.update,
            initialized: false,
            showChannelGroup: false, // if true shows channel group edit view for selected channel group
            chGroupLangs: undefined, // languages which are included in the currently selected channel group
            isSavedSuperProfile: undefined, // set to true if profile is already saved as 'super profile' in backend (user will not be allowed to change it back to become a 'simple' profile)
            tryQuery: undefined,    // if set, this component renders the search component with specified query, language, channel group
            spamCheckFinishedCalls: 0,
            spamCheckTotalCalls: 0,
            someSpamFailed: false,  // true if spam check for at least one query failed
            spamSuccess: undefined,
            activeTab: 0,
            showDeleteProfileModal: false, // if true, modal "really delete profile" gets shown
        };

        this.onTextfieldChange = this.onTextfieldChange.bind(this);
        this.onStandardQueryChange = this.onStandardQueryChange.bind(this);
        this.addQueryGroup = this.addQueryGroup.bind(this);
        this.updateQueryGroup = this.updateQueryGroup.bind(this);
        this.removeSuperQuery = this.removeSuperQuery.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.toggleCheckbox = this.toggleSwitch.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.deleteProfile = this.deleteProfile.bind(this);
        this.onShareGroupsChange = this.onShareGroupsChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.acceptThresholdExceedance = this.acceptThresholdExceedance.bind(this);
        this.updateQueryGroupType = this.updateQueryGroupType.bind(this);
        this.disableQuery = this.disableQuery.bind(this);
        this.onQueryLangChange = this.onQueryLangChange.bind(this);
        this.removeLanguage = this.removeLanguage.bind(this);
        this.renderSpamFailed = this.renderSpamFailed.bind(this);
        this.updateQueryPosition = this.updateQueryPosition.bind(this);
        this.selectQuery = this.selectQuery.bind(this);
        this.hasDupQueryTitleForLang = this.hasDupQueryTitleForLang.bind(this);
        this.runSpamCheck = this.runSpamCheck.bind(this);
        this.updateStandardQuery = this.updateStandardQuery.bind(this);
        this.setQueryValid = this.setQueryValid.bind(this);
        this.setTryQueryProfileId = this.setTryQueryProfileId.bind(this);
    }

    async componentDidMount() {
        // TODO: bug #1530:
        // - run checkQuerySyntax() for all queries within componentDidMount (should be done for all once at "open profile")
        // - change AutoComplete to allow display after handing over result as prop
        if (this.props.prefill) {

            _.map(this.props.prefill.profile, (val, key) => {
                this.setState(s => {
                    s.profile[key] = val;
                    return { profile: s.profile };
                });
            });            
        }

        let profile;
        if (this.state.update) {
            trackEvent(this.props.matomo, 'profile', 'editProfileViewOpened', { profileId: this.props.profileId });
            profile = (await APIProfiles.get(this.props.profileId)).data;
            // profile.queries.sort((a, b) => a.title > b.title && 1 || -1);    // todo
            profile.shareGroups = (await APIProfiles.getShareGroups(this.props.profileId)).data;
        } else {
            trackEvent(this.props.matomo, 'profile', 'addProfileViewOpened');
            profile = this.state.profile;
        }

        if (!this.state.update && !this.props.prefill) profile.profileGroupId = this.props.groupId;

        const profileGroups = (await APIProfileGroups.get()).data;
        this.props.getChannelGroups();
        this.props.getSchedules();

        const shareGroups = (await APIMe.shareGroups()).data;

        this.availableShareGroups = _.map(shareGroups, g => ({
            label: g.title,
            value: g.id
        }));

        profile.shareGroups = _.filter(profile.shareGroups, g =>
            _.find(this.availableShareGroups, { value: g.id })
        ).map(sg=>sg.id);
        profile.initShareGroups = _.cloneDeep(profile.shareGroups);

        profile.updatedShareGroups = [];

        let update = { profile, profileGroups, };
        if (this.state.update) {
            update.settingsCollapsed = true;
        }
        if (profile.isSuper) update.isSavedSuperProfile = true;

        if (profile.channelGroupId || profile.channelGroupId === null) {
            update = await this.fetchLanguages(profile.channelGroupId, update);
        }

        update.profile.hasQuery = this.hasQuery(update.profile.queries);

        if (update.profile.queries['xx'] && update.profile.queries['xx'].standardQuery) {
            update.profile.hadXXOnLoad = true;
        }

        if (!config.allowMixedProfileTypesInGroup && update.profile.id === undefined && !update.profile.isSuper) {
            const profiles = _.find(update.profileGroups, { id: update.profile.profileGroupId }).profiles;
            const simpleAllowed = this.isAllowedInGroup(profiles, false);
            const superAllowed = this.isAllowedInGroup(profiles, true);
            if (!simpleAllowed && superAllowed) { update.profile.isSuper = true; }
        }

        // let initialQueryTrees = []
        // profile.queries.forEach(element => {
        //     initialQueryTrees.push(null)
        // });
        this.setState({
            ...update,
            // queryTree: initialQueryTrees,
            initialized: true,
        }, async() => {
            if (this.props.prefill?.init) { await this.props.prefill.init(this); }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.channelGroupReducers !== this.props.channelGroupReducers) {
            this.setState({channelGroups: this.props.channelGroupReducers.filter(cg => !cg.title.includes(config.searchChannelGroupTitle))});
        }
    }

    validate(profile) {
        this.setState(state => {
            let edited = true;

            const p =  profile || state.profile;
            if (!p.profileGroupId) edited = false;
            else if (p.channelGroupId === undefined) edited = false;
            else if (p.title.length === 0) edited = false;
            else if (p.queries.length === 0) edited = false;

            return { edited };
        });
    }

    async fetchLanguages(channel, state=this.state, cb=()=>{}) {
        state = _.cloneDeep(state);
        let languages;
        if (this.props.sharedBy) {
            // if profile is shared, we do not check for languages - we simply show all languages which contain queries (queries can not be added/removed anyways)
            // note: user may not even have access to channels in languages which are used in shared profile
            languages = Object.keys(state.profile.queries);
        } else {
            languages = (await APIChannelGroups.languages(channel)).data;
        }
        const xxIdx = languages.findIndex(val => val === 'xx');
        if (xxIdx !== -1) {
            languages.splice(xxIdx, 1);
            state.channelGroupHasXX = true;
        } else {
            state.channelGroupHasXX = false;
        }
        if (
            !this.props.sharedBy
            || state.profile.queries['**'] !== undefined
            // for shared profiles, add '**' only if profile contains a query for that language
        ) {
            languages.push('**');
        }

        let queries = _.cloneDeep(state.profile.queries);

        // find queries which will be deleted when switching channel group
        let outdated = [];
        Object.keys(state.profile.queries).forEach(langKey => {
            if (langKey !== 'xx' && !languages.includes(langKey)) {
                let q = queries[langKey];
                let simple = q.standardQuery;
                let superQ = q.superQuery;
                if (!state.profile.isSuper && simple && simple.query && !simple.delete) {
                    outdated.push(this.props.t(langKey));
                } else if(state.profile.isSuper && superQ && superQ.some(o => o.query && !o.delete)){
                    outdated.push(this.props.t(langKey));
                }
            }
        });

        // ask user for confirmation if some queries would be deleted after switch of channel group
        let confirmed = true;
        if(outdated.length > 0){
            confirmed = window.confirm(`${this.props.t('profile_delete_all_queries_for_lang')}:\n\n${outdated.join('\n')}\n\n${this.props.t('sure_continue')}`);
        }
        Object.keys(state.profile.queries).forEach(langKey => {
            if (langKey !== 'xx' && !languages.includes(langKey)) {
                // remove queries without content if languages do not match selected channelgroup anymore
                let q = queries[langKey];
                let simple = q.standardQuery;
                let superQ = q.superQuery;
                if (simple) {
                    if(simple.id){
                        simple.delete = true;
                        simple.disabled = false;
                        simple.query = undefined;
                    } else{
                        q.standardQuery = undefined;
                    }
                }
                if (superQ){
                    superQ = superQ.map(o => {
                        if(o.id){
                            o.delete = true;
                        } else {
                            o = undefined;
                        }
                        return o;
                    });
                    superQ = superQ.filter(o => o !== undefined);
                    if(superQ.length === 0) q.superQuery = undefined;
                }
            }
        });
        state.profile.queries = queries;
        
        // add languages which have not been there for prev. selected channel group
        _.map(languages, (lang,index) => {
            let oldQuery = state.profile.queries[lang];
            if (!oldQuery) {
                this.newQueriesCount++;
                state.profile.queries[lang] = {
                    standardQuery: {client_id: this.newQueriesCount},
                    superQuery: []
                }
            } else {
                if(!oldQuery.standardQuery){
                    state.profile.queries[lang].standardQuery = {};    
                }
                if(!oldQuery.superQuery){
                    state.profile.queries[lang].superQuery = [];    
                }
            }
        });

        if(!confirmed) { 
            cb(true);
        } else {
            this.setState({
                profile: state.profile,
                chGroupLangs: languages,
                activeTab: 0,   // switch to first tab (new channelgroup may not contain any channel for the currently active language)
                // TODO: only change activeTab=0 if lang. does not exist in channel group
            }, ()=>cb());
        }
        return state;
    }

    updateStandardQuery(languageIdx, query){    // updates all props provided in query param
        if(!this.state.profile?.queries[languageIdx]?.standardQuery){
            return -1;  // query does not exist, nothing to update here
        }
        const curr = this.state.profile.queries[languageIdx].standardQuery;
        this.setState((state)=>{
            if(query.query !== curr.query){
                state.profile.queries[languageIdx].standardQuery.queryEdited = true;
                this.validate();
            }
            Object.keys(query).map(key=>{
                state.profile.queries[languageIdx].standardQuery[key] = query[key];
            })
            return state;
        })
    }

    async onStandardQueryChange(event, languageIdx, showTree) {
        if (languageIdx === undefined) {     //if no other language is given, it is set to the default (first language in channel group)
            const langs = Object.keys(this.state.profile.queries);
            languageIdx = langs.length > 0 ? langs[0] : undefined;
        }
        if(languageIdx !== -1) {            //if the channel group has no languages
            const val = event.target.value;
            this.setState(state => {
                state.profile.queries[languageIdx].standardQuery.query = val;
                state.profile.queries[languageIdx].standardQuery.delete = undefined;
                state.profile.queries[languageIdx].standardQuery.queryEdited = true;
                let hasQuery = val ? true : this.hasQuery(state.profile.queries);

                return { profile: {...state.profile, hasQuery}}
            });

            this.validate()
        }
    }

    addQueryGroup(type, languageIdx, title, query, isQueryValid) {  // TOOD: rename querygroup --> superQuery
        this.setState(state => {
            if (state.profile.queries[languageIdx].superQuery === undefined) state.profile.queries[languageIdx].superQuery = [];  // add superQuery type (empty array) if not existing yet
            let uiPosMax = _.maxBy(state.profile.queries[languageIdx].superQuery, 'uiPosition');
            this.newQueriesCount++;
            state.profile.queries[languageIdx].superQuery.push({
                id: null,
                client_id: this.newQueriesCount,
                fallbackTitle: `${this.props.t('Title')} ${uiPosMax ? uiPosMax.uiPosition + 2 : 1}`,
                title: title,
                query: query,
                isValid: isQueryValid,
                showVis: false,
                type,
                uiPosition: uiPosMax ? uiPosMax.uiPosition + 1 : 0,
                queryEdited: true,
            });

            return { profile: {...state.profile} };
        });

        this.validate();
    }

    setDefaultTitles(queries) {
        queries = _.cloneDeep(queries);
        _.map(queries, (q, langKey) => {
            if(queries[langKey].superQuery){
                queries[langKey].superQuery.map((q, index) => {
                    if (!q.title) {
                        q.title = q.fallbackTitle || this.props.t('Title');
                    }
                })
            }
        })
        this.setState({profile:{...this.state.profile, queries}})
        return queries;
    }


    updateQueryGroupType(languageIdx, index, newType){  // TOOD: rename querygroup --> superQuery
        this.setState((state) => {
            state.profile.queries[languageIdx].superQuery[index].type = newType;
            state.profile.queries[languageIdx].superQuery[index].queryEdited = true;
            return {profile: {...state.profile}};
        });
        this.validate();
    }

    selectQuery(bool, languageIdx, index) {
        this.setState((s) => {
            if (index !== undefined) {    // superQuery
                s.profile.queries[languageIdx].superQuery[index].selected = bool;
            } else {

            }
            return { profile: s.profile };
        });
    }

    hasDupQueryTitleForLang(lang, queries) {  // checks if any title within specified language is used more than once
        let hasDup = false;
        Object.keys(queries).map((langKey) => {
            if(queries[langKey].superQuery){
                queries[langKey].superQuery.map((q, i) => {
                    const isDup = queries[lang].superQuery.filter(e => e.title === q.title && !e.delete).length > 1 ? true : false;
                    if (isDup) { hasDup = true; }
                    if (langKey === lang) {
                        queries[lang].superQuery[i].dupTitle = isDup;
                    }
                });
            }
        })
        return hasDup;
    }

    // sets query syntax validity (we need it to prevent save if invalid query is present)
    setQueryValid(isValid, languageIdx, index) {
        if (this.state.profile.isSuper) {
            this.setState((state) => {
                state.profile.queries[languageIdx].superQuery[index].isValid = isValid;
                return state;
            });
        } else {
            this.setState((state) => {
                state.profile.queries[languageIdx].standardQuery.isValid = isValid;
                return state;
            });
        }
    }

    hasInvalidQuery(queries) {  // returns true if any of the provided query is set to isValid=false
        return Object.keys(queries).some(q => {
            return queries[q].superQuery?.some(sq => { return sq.isValid === false })
            || queries[q].standardQuery?.isValid === false
        })
    }
    
    updateQueryGroup(languageIdx, index, data) {    // TOOD: rename querygroup --> superQuery
        this.setState((state) => {
            if (state.profile.queries[languageIdx].superQuery[index].query !== data.query
            || state.profile.queries[languageIdx].superQuery[index].title !== data.title
            ) {
                state.profile.queries[languageIdx].superQuery[index].queryEdited = true;
            }
            if(data.isValid !== undefined){
                state.profile.queries[languageIdx].superQuery[index].isValid = data.isValid;
            }
            state.profile.queries[languageIdx].superQuery[index].title = data.title;
            state.profile.queries[languageIdx].superQuery[index].query = data.query;
            state.profile.hasQuery = data.query ? true : this.hasQuery(state.profile.queries);
            state.profile.queries[languageIdx].superQuery[index].showVis = data.showVis;
            state.profile.queries[languageIdx].superQuery[index].tWouldOverride = data.tWouldOverride;  // true if currently selected translation settings would override query
            state.profile.queries[languageIdx].superQuery[index].tOverride = data.tOverride;    // true if user accepted to override query with translation
            const hasDupTitles = this.hasDupQueryTitleForLang(languageIdx, state.profile.queries);
            return { profile: { ...state.profile, hasDupTitles } };
        });
        this.validate();
    }

    removeSuperQuery(languageIdx, index, profile) {
        profile = _.cloneDeep(profile);
        if(profile.queries[languageIdx].superQuery[index].id){  // handle existing profiles
            profile.queries[languageIdx].superQuery[index].delete = true;
            profile.hasQuery = this.hasQuery(profile.queries);
        } else {    // handle unsaved profiles
            profile.queries[languageIdx].superQuery.splice(index, 1);
            profile.hasQuery = this.hasQuery(profile.queries);
        }
        profile.hasDupTitles = this.hasDupQueryTitleForLang(languageIdx, profile.queries);
        this.validate(profile);
        return profile;
    }

    onTextfieldChange(event, key) {
        const val = event.target.value;

        this.setState(state => {
            state.profile[key] = val;
            return { profile: state.profile };
        });

        this.validate();
    }

    onSelectChange(option, key) {
        this.setState(state => {
            state.profile[key] = option.value;
            return { profile: state.profile };
        });

        this.validate();
    }

    toggleSwitch(key) {
        this.setState(state => {
            state.profile[key] = state.profile[key] ? false : true;
            return { profile: state.profile, edited: true };
        });
    }

    // options = [{label: -, value: -}, ... ]
    onShareGroupsChange(options, change) {
        let obj;
        if (change.option !== undefined) obj = { id: change.option.value, inGroup: true };
        if (change.removedValue !== undefined) obj = { id: change.removedValue.value, inGroup: false }
        
        this.setState(state => {
            let sgs;
            if (change.action === 'clear') {
                state.profile.shareGroups = [];
                state.profile.updatedShareGroups = _.map(state.profile.initShareGroups, (sg) => ({ id: sg, inGroup: false }));
            } else {
                sgs = state.profile.updatedShareGroups;
                state.profile.shareGroups = _.map(options, o => o.value);
                let updateIdx = _.findIndex(sgs, {id: obj.id});
                let existed = state.profile.initShareGroups.some(e => e === obj.id);
                const needsUpdate = (existed && !obj.inGroup) || (!existed && obj.inGroup);
                if(needsUpdate && updateIdx < 0){
                    sgs.push(obj);
                } else if (!needsUpdate && updateIdx >= 0){
                    sgs.splice(updateIdx, 1);
                }
            }
            return {
                profile: state.profile,
                // updatedShareGroups: sgs
            };
        });

        this.validate();
    }

    mapShareGroups() {
        const shareGroups = _.map(this.state.profile.shareGroups, g => {
            let potentialGroup = _.find(this.availableShareGroups, {
                value: g
            });

            if (!potentialGroup) return null;

            return {
                label: potentialGroup.label,
                value: g
            };
        });

        return _.filter(shareGroups, g => g !== null);
    }

    async acceptThresholdExceedance() {
        await this.setState(state => {
            state.profile.accept = state.saveFailed.data.spam;
            return {
                profile: state.profile
            };
        });
        this.saveProfile();
    }

    onBeforeSave(queries, isSuperProfile){
        queries = _.cloneDeep(queries);
        let isSuper = isSuperProfile;
        let warn = false;
        let hasXX = false;
        Object.keys(queries).forEach((langKey)=>{
            const hasStandardQuery = (queries[langKey].standardQuery && hasContent(queries[langKey].standardQuery.query));
            const hasSuperQuery = (queries[langKey].superQuery && queries[langKey].superQuery.some(o=>hasContent(o.query)));
            if (langKey === 'xx' && (hasSuperQuery || hasStandardQuery)) hasXX = true;
            
            // deleting queries which are of type 'simple' (if profile is set to be a super profile)
            if( hasStandardQuery && isSuper ){
                warn = true;
                queries[langKey].standardQuery.id
                    ? queries[langKey].standardQuery.delete = true
                    : queries[langKey].standardQuery = undefined;
            }
            // deleting queries which are of type 'super' (if profile is set to be a simple profile)
            if( hasSuperQuery && (!isSuper || langKey === 'xx') ){
                queries[langKey].superQuery = queries[langKey].superQuery.map( (o, index) =>{
                    if(hasContent(o.query)){
                        warn = true;
                        o.id
                            ? o.delete = true
                            : o = undefined;
                    }
                    return o;
                });
                queries[langKey].superQuery = queries[langKey].superQuery.filter(o=>o!== undefined);
            }
        });

        if (hasXX && !isSuper) {
            // 1. aborting if lang=xx present
            this.setState({ queryWithNoLanguage: true });
            return undefined;
        } else {
            queries = this.setDefaultTitles(queries);
            return queries;
        }
    }

    onIsSuperChange() {
        const queries = this.state.profile.queries;
        const hasStandardQuery = Object.keys(queries).some(langKey => queries[langKey].standardQuery && hasContent(queries[langKey].standardQuery.query));
        const hasSuperQuery = Object.keys(queries).some(langKey => queries[langKey].superQuery && queries[langKey].superQuery.some(o=>hasContent(o.query)));
        let confirm = true;
        if (
            (hasStandardQuery && this.state.profile.isSuper !== true) ||
            (hasSuperQuery && this.state.profile.isSuper === true) 
        ) {
            confirm = window.confirm(
                `${this.props.t('profile_confirm_lose_queries')}`
            )
        }
        if (confirm) {
            if(!config.allowMixedProfileTypesInGroup){
                const isAllowed = this.isAllowedInGroup(
                    _.find(this.state.profileGroups, { id: this.state.profile.profileGroupId }).profiles,
                    this.state.profile.isSuper ? false : true,
                    this.state.profile.id,
                );
                if (!isAllowed) {
                    this.setState({ mixedGroup: true });
                }
            }
            this.toggleSwitch('isSuper');
        }
    }

    resetQueryEdited() {
        const p = _.cloneDeep(this.state.profile);
        Object.keys(p.queries).map(lang => {
            if (p.isSuper && p.queries[lang].superQuery) {
                p.queries[lang].superQuery.map(q => { q.queryEdited = false; })
            } else if (p.queries[lang].standardQuery) {
                p.queries[lang].standardQuery.queryEdited = false;
            }
        })

        this.setState({ profile: p });
    }

    removeEmptyQueries(profile) {   // remove empty string queries (should be called before save to backend)
        profile = _.cloneDeep(profile);
        Object.keys(profile.queries).forEach((langKey) => {
            if (
                profile.queries[langKey].standardQuery
                && !profile.queries[langKey].standardQuery.query
                && profile.queries[langKey].standardQuery.id !== undefined
            ) {
                // if query is empty and has id, set delete=true (will be sent to backend)
                profile.queries[langKey].standardQuery.delete = true;
            }
    
            if (profile.queries[langKey].superQuery) {
                // if query is empty and not saved yet(no id), remove from client only
                profile.queries[langKey].superQuery.map((q,i) => {
                    if(!q.query){
                        profile = this.removeSuperQuery(langKey, i, profile);
                    }
                });
            }
        });
        return profile;
    }

    async saveProfile() {
        if (!this.state.edited) return;
        this.setState({saveSuccessful: false})
        if (this.hasInvalidQuery(this.state.profile.queries)) {
            this.setState({ showInvalidQueriesModal: true });
            return;
        }
        if(!config.allowMixedProfileTypesInGroup){
            const isAllowed = this.isAllowedInGroup(
                _.find(this.state.profileGroups, { id: this.state.profile.profileGroupId }).profiles,
                this.state.profile.isSuper,
                this.state.profile.id,
            );
            if (!isAllowed) {
                this.setState({ mixedGroup: true });
                return;
            }
        }

        let profile = this.removeEmptyQueries(_.cloneDeep(this.state.profile));

        let calls = [];
        profile.queries = this.onBeforeSave(profile.queries, profile.isSuper);
        let save = profile;
        if (!save?.queries) return;

        let shareGroups = {add:[], remove:[]};
        save.updatedShareGroups.map((i) => i.inGroup ? shareGroups.add.push(i.id) : shareGroups.remove.push(i.id));

        const saveShareGroups = async (sgs, profileId)=>{
            profileId = profileId !== undefined ? profileId : this.state.profile.id;
            if (sgs.add.length > 0) {
                calls.push(await APIProfiles.addShareGroups(profileId, sgs.add.join(',')));
            }
            if (sgs.remove.length > 0) {
                calls.push(await APIProfiles.removeShareGroups(profileId, sgs.remove.join(',')));
            }
        }

        if (!this.props.me?.can_AutoSummarize && save.autoSumLang) {
            // if user has no permission to set auto-summarizer language on a profile: do not send language to backend, even if profile already has lang. set (e.g. because done by eMM)
            // backend will still keep the set language in this case
            delete save.autoSumLang;
        }

        let saveRes;
        const saveRedux = {
            isEnabled: Number(save.isEnabled), 
            enabled: Number(save.isEnabled), 
            id: save.id,
            isSuper: Number(save.isSuper),
            super: Number(save.isSuper),
            title: save.title,
        }
        if (this.state.update) {
            const savedProfileGroupId = this.props.groupId;
            const newProfileGroupId = savedProfileGroupId !== this.state.profile.profileGroupId ? this.state.profile.profileGroupId : undefined;
            this.props.updateProfile(savedProfileGroupId, saveRedux, newProfileGroupId);
            const tempChannelGroups = this.props.channelGroupReducers.filter((chGroups) => {
                return chGroups.title.startsWith(config.searchChannelGroupTitle)
            })
            const allProfiles = this.props.profileGroups.data.flatMap((profileGroup) => profileGroup.profiles);
            const searchProfile = allProfiles.find((profile) => profile.id === this.state.profile.id)
            const searchGroup = this.props.profileGroups.data.find((group) => group.title === `${config.searchQueryGroupTitle}`)
            const tempProfileChannelGroup = tempChannelGroups.find((channelGroup) => {
                return channelGroup.title === `${config.searchChannelGroupTitle}${searchProfile.title}`
            })
            const isProfileExistsInSearchGroup = searchGroup?.profiles.some((profile) => {
                return profile.id === searchProfile.id
            })
            saveRes = await APIProfiles.put(this.state.profile.id, {
                ...save,
                channelGroupId: profile.channelGroupId === tempProfileChannelGroup?.id && isProfileExistsInSearchGroup ? null : save.channelGroupId
            });
            if(tempProfileChannelGroup && (savedProfileGroupId !== this.state.profile.profileGroupId)){
               this.props.deleteGroup(tempProfileChannelGroup.id) 
            }
            calls.push(saveRes);
            await saveShareGroups(shareGroups);
        } else {
            saveRes = await APIProfiles.post(save);
            calls.push(saveRes);
            if(saveRes.rc === 0){
                this.props.addProfile(this.props.groupId, {...saveRedux, id: saveRes.data.id});
                await saveShareGroups(shareGroups, saveRes.data.id);
            }
        }
        const status = calls.some(e => e.status || e.rc !== 0);
        if (status) {
            saveRes.data = _.map(saveRes.data, d => {
                return { x: d.date, y: d.hits };
            });
            this.setState((s)=>{
                s.profile.updatedShareGroups = [];
                
                return {
                    saveFailed: saveRes.data,
                    saveSuccessful: false,
                    profile:s.profile
                }
            });
        } else {
            this.setState((state) => {
                state.profile.accept = undefined;
                if (!this.state.update) {
                    state.profile.id = saveRes.data.id;
                }
                trackEvent(this.props.matomo, 'profile', 'save', {profileId: state.profile.id,isSuper:state.profile.isSuper});
                if (state.profile.isSuper) state.isSavedSuperProfile = true;
                state.profile.updatedShareGroups = [];
                
                let queries = state.profile.queries;
                Object.keys(queries).forEach(key => {
                    // only keep queries in state, which do not have delete=true set (they have been deleted by the user already)
                    if (queries[key].standardQuery && queries[key].standardQuery.delete === true) {
                        this.newQueriesCount++; // ensure that standardQuery gets a new client_id after being deleted from backend
                        queries[key].standardQuery = {
                            client_id: this.newQueriesCount
                        };
                    }
                    if(queries[key].superQuery){
                        queries[key].superQuery = queries[key].superQuery.filter(q=>q.delete !== true);
                    }

                    // replace client_id with received database id for queries where required
                    if (saveRes.queryIds && saveRes.queryIds.length > 0) {
                        let index;
                        for (let i = 0; i < saveRes.queryIds.length; i++){
                            let o = saveRes.queryIds[i];
                            index = _.findIndex(queries[key].superQuery, { client_id: o.client_id });
                            if (index !== -1 && queries[key] && queries[key].superQuery) {
                                queries[key].superQuery[index].id = o.qid;
                                delete queries[key].superQuery[index].client_id;
                            } else if (
                                queries[key] && queries[key].standardQuery &&
                                queries[key].standardQuery.client_id === o.client_id
                            ) {
                                queries[key].standardQuery.id = o.qid;
                                delete queries[key].standardQuery.client_id;
                            }
                        }
                    }
                });
                

                state.profile.initShareGroups = state.profile.shareGroups;

                return {
                    saveFailed: undefined,
                    saveSuccessful: true,
                    profile: state.profile,
                    edited: false,
                    update: true
                };
            }, async () => {
                await this.runSpamChecks();
            });
        }
    }

    async runSpamCheck(query, key, index){
        const profile = this.state.profile;
        if(profile.isSuper){        
            const res = await APIProfiles.spamCheck(profile.id, query.id, [90071, 90072]);
            this.setState((s)=>{
                s = _.cloneDeep(s);

                s.profile.queries[key].superQuery[index].spamStatus = res.rc;

                if (
                    res.spam > 0
                    // [90071, 90072].includes(res.rc)
                ) {
                    s.profile.queries[key].superQuery[index].spamCheck = res;
                } else {
                    s.profile.queries[key].superQuery[index].spamCheck = undefined;
                }
                trackEvent(this.props.matomo, 'profile', 'spamCheck', {
                    lang: key,
                    q: s.profile.queries[key].superQuery[index].query,
                    rc: res.rc,
                    spam: res.spam,
                })
                s.spamCheckFinishedCalls = this.state.spamCheckFinishedCalls+1;
                return s;
            });
            return res;
        } else {
            let check = async function () {
                const res = await APIProfiles.spamCheck(profile.id, query.standardQuery.id, [90071, 90072]);
                this.setState((s)=>{
                    s = _.cloneDeep(s);

                    s.profile.queries[key].standardQuery.spamStatus = res.spam;

                    if (
                        // [90071, 90072].includes(res.rc) ||
                        res?.spam > 0
                    ) {
                        s.profile.queries[key].standardQuery.spamCheck = res;
                    } else {
                        s.profile.queries[key].standardQuery.spamCheck = undefined;
                    }
                    trackEvent(this.props.matomo, 'profile', 'spamCheck', {
                        lang: key,
                        q: query.standardQuery.query,
                        rc: res.rc,
                        spam: res.spam,
                    })
                    s.spamCheckFinishedCalls = this.state.spamCheckFinishedCalls+1;
                    return s;
                });
                return res;
            };
            check = check.bind(this);
            return check();
        }
    }

    async runSpamChecks() {
        let profile = this.state.profile;
        let queries = _.cloneDeep(profile.queries);
        let calls = [];
        let numberOfTotalCalls = 0;

        Object.keys(queries).forEach((key) => {
            if (this.state.profile.isSuper) {
                if (!queries[key].superQuery) return;

                calls = calls.concat(queries[key].superQuery.map(async (sq, index) => {
                    if (sq.queryEdited === true) {    // do not run spam check for queries which have not been changed since last save
                        if (!profile.isEnabled || sq.disabled || (sq.query === '' || sq.query === undefined)) {
                            // reset spam check in gui and do not execute call if profile or query is disabled
                            this.setState((s) => {
                                s = _.cloneDeep(s);
                                s.profile.queries[key].superQuery[index].spamStatus = undefined;
                                s.profile.queries[key].superQuery[index].spamCheck = undefined;
                                return s;
                            });
                            return {};
                        } else {
                            numberOfTotalCalls++;
                            return await this.runSpamCheck(sq, key, index);
                        }
                    } else {
                        return -1;  // allow removing items from "calls" which are no actual calls due to if condition here
                    }
                }));
            } else {
                if (
                    queries[key].standardQuery && queries[key].standardQuery.id
                    && queries[key].standardQuery.queryEdited   // do not run spam check for queries which have not been changed since last save
                ) {
                    if (!profile.isEnabled || queries[key].standardQuery.disabled) {
                        // reset spam check in gui and do not execute call if profile or query is disabled
                        this.setState((s) => {
                            s = _.cloneDeep(s);
                            s.profile.queries[key].standardQuery.spamStatus = undefined;
                            s.profile.queries[key].standardQuery.spamCheck = undefined;
                            return s;
                        })
                        return {};
                    } else {
                        numberOfTotalCalls++;
                        calls.push(this.runSpamCheck(queries[key], key));
                    }
                }
            }
        });
        console.log(numberOfTotalCalls)
        this.setState({
            spamCheckPendingCalls: calls.length,
            spamCheckTotalCalls: numberOfTotalCalls,
        })
        calls = await Promise.all(calls);
        calls = calls.filter(c => c !== -1);
        const someSpamFailed = calls.some(e => e.spam > 0
            // || [90071, 90072].includes(e.rc)
        );

        this.resetQueryEdited();

        this.setState({
            spamCheckTotalCalls: 0,
            spamCheckFinishedCalls: 0,
            someSpamFailed,
            spamCheckOk: !someSpamFailed && this.state.spamCheckTotalCalls > 0,
        })
    }

    async deleteProfile() {
        await APIProfiles.delete(this.state.profile.id);
        this.props.deleteProfile(this.props.groupId, this.state.profile.id);
        this.props.onGoBack(true);
    }

    onDrop(files) {
        let validFiles = [];
        _.map(files, file => {
            if (file.type.match('^image/.*') === null) {
                // TODO: handle notice for user that chosen file type is not supported
                return;
            }
            validFiles.push(URL.createObjectURL(file));
        });
        this.setState(state => {
            state.profile.logos = state.profile.logos.concat(validFiles);
            return { profile: state.profile };
        });
    }

    removeLogo(logoIndex) {
        this.setState(state => {
            state.profile.logos.splice(logoIndex, 1);
            return { profile: state.profile };
        });
    }

    renderSpamFailed(spamCheckResp) {
        // translation method with fallback
        const t = this.props.t || (k => k);
        let data = spamCheckResp && spamCheckResp.stats;

        data = _.map(data, (d) => {
            return { name: d[0], data: { hits: d[1] }}
        })

        return (
            <AlertBox>
                <div>{
                    spamCheckResp.rc === 90072 && 
                        `${this.props.t('profile_query_spam_text')}.`
                    }</div>
                <div className={styles.graph}>
                    <ReChart>
                        <BarChart
                            hideLegend={true}
                            yLabel={t('Mentions')}
                            xLabel={t('Date')}
                            data={data} />
                    </ReChart>
                </div>
                {/* {spamCheckResp.rc === 90071 ? (
                    <div className={styles.saveQueryBtn}>
                        <Button
                            onClick={this.acceptThresholdExceedance}
                            type='secondary'
                        >
                            {t('Increase & Save Query')}
                        </Button>
                    </div>
                ) : null} */}
            </AlertBox>
        );
    }

    disableQuery(val, languageKey, superQueryIndex) {
        this.setState((s) => {
            let profile = _.cloneDeep(s.profile);
            let query = superQueryIndex === undefined
                ? profile.queries[languageKey].standardQuery
                : profile.queries[languageKey].superQuery[superQueryIndex];
            query.disabled = val;
            query.queryEdited = true;
            return { profile };
        });
        this.validate();
    }

    onQueryLangChange(from, to) {    // used for 'no language' query aka lang='xx' query
        let existingQuery;
        // if (superQueryIndex) {
        //     // super profiles should not have lang=xx
        // } else {
            existingQuery = this.state.profile.queries[to.value].standardQuery.query;
        // }
        let confirmed = true;
        if (existingQuery) {
            confirmed = window.confirm(`${this.props.t('profile_change_lang_confirm')} "${to.label}"`)
        }

        if(confirmed){
            this.setState((s) => {
                s = _.cloneDeep(s);
                // if (superQueryIndex) {
                //     // super profiles should not have lang=xx
                // } else {
                const fromQuery = _.cloneDeep(s.profile.queries[from.value].standardQuery);
                s.profile.queries[to.value].standardQuery.query = fromQuery.query;
                s.profile.queries[from.value].standardQuery.query = undefined;

                s.profile.queries[from.value].standardQuery.queryEdited = true;
                s.profile.queries[to.value].standardQuery.queryEdited = true; // marking query as edited - this will trigger spam check for the query when saving
                // }
                return s;
            })
            this.validate();
        }
    }

    removeLanguage(lang) {
        // removes provided language from standardQuery
        this.setState((s) => {
            s = _.cloneDeep(s);
            if (s.profile.queries[lang].standardQuery !== undefined) {
                s.profile.queries[lang].standardQuery.id !== undefined
                    ? s.profile.queries[lang].standardQuery.delete = true
                    : delete s.profile.queries[lang].standardQuery;
            }
            return s;
        });
        this.validate();
    }

    hasQuery(queries){
        let rv = false;
        if(this.state.profile.isSuper){
            rv = Object.keys(queries)
                .some(lang => {
                    let rv = queries[lang] && queries[lang].superQuery && queries[lang].superQuery
                        .some(sq => { return sq.query && !sq.delete } )
                    return rv;
                });
        } else{
            rv = Object.keys(this.state.profile.queries)
                .some(lang => 
                    queries[lang]
                    && queries[lang].standardQuery
                    && queries[lang].standardQuery.query
                    && queries[lang].standardQuery.delete !== true
                );
        }
        return rv;
    }

    updateQueryPosition(lang, oldPos, newPos, callback){
        this.validate();
        // update uiPosition numbers of all affected elements (after use of drag & drop)
        this.setState((s)=>{
            let stateUpdate = _.cloneDeep(s);
            // TODO: improvement: use helpers-tsx.reorderItems() instead
            if(newPos > oldPos){    //move down
                stateUpdate.profile.queries[lang].superQuery = stateUpdate.profile.queries[lang].superQuery.map((q, i) => {
                    if (q.uiPosition > oldPos && q.uiPosition <= newPos) {
                        q.uiPosition--;
                        q.queryEdited = true;
                    }
                    else if(q.uiPosition === oldPos){
                        q.uiPosition = newPos;
                        q.queryEdited = true;
                    }
                    return q;
                });
            }
            if (newPos < oldPos) {  // move up
                stateUpdate.profile.queries[lang].superQuery = stateUpdate.profile.queries[lang].superQuery.map((q,i) => {
                    if (q.uiPosition >= newPos && q.uiPosition < oldPos) {
                        q.uiPosition++;
                        q.queryEdited = true;
                    } else if(q.uiPosition === oldPos){
                        q.uiPosition = newPos;
                        q.queryEdited = true;
                    }
                    return q;
                });
            }
            return stateUpdate;
        }, callback);
    }

    isAllowedInGroup(group, isSuper, profileId) {
        // returns boolean which indicates if moving to the passed group is allowed. rules:
        // 1. all profiles within the provided group need to have the same type (v2-created / v3-simple / v3-super)
        // 2. ignore v2-created for the current profile - as 'save' ensures conversion to v3-profile anyways
        
        return !group.some( (p) => p.id !== profileId && (p.isSuper === null || p.isSuper != isSuper) );
    }

    setTryQueryProfileId (profileID) {
        this.setState(prevState => ({
            tryQuery:{
                ...prevState.tryQuery,
                profileID: profileID
        }}));
    }

    render() {

        if (!this.state.initialized) {
            return <LoadingSpinner />;
        }

        if(this.state.showChannelGroup){

            return <ChannelGroup
            groupId={this.state.profile.channelGroupId}
            onGoBack={()=>{ this.setState({showChannelGroup: false})}}
            />;
        }

        const profile = this.state.profile;

        const profileGroupOptions = _.map(this.state.profileGroups, group => ({
            label: group.title,
            value: group.id
        }));
        const profileGroupSelected = _.find(profileGroupOptions, {
            value: profile.profileGroupId
        });

        const staticChannelGroupOptions = [{
            label: this.props.t('ALL'),
            value: null,
        }]
        const channelGroupsWithChannels = this.state.channelGroups?.filter(cg => cg.channels?.length || cg.groups?.length);
        const channelGroupOptions = staticChannelGroupOptions.concat(
            _.map(channelGroupsWithChannels, group => ({
                label: group.title,
                value: group.id
            })).filter(g => !g.label.endsWith('tryQuery'))
        );
        const channelGroupSelected = _.find(channelGroupOptions, {
            value: profile.channelGroupId
        });

        const notifyStaticOptions = [
            { label: this.props.t('disabled'), value: 0, },
            { label: this.props.t('real time'), value: 1, },
        ]
        const notifyEmailOptions = notifyStaticOptions.concat(this.props.schedules.status === STATUS_SUCCEEDED
            ? this.props.schedules.schedules.map((s) => ({
                label: s.title,
                value: s.id,
            }))
            : []
        );
        const notifyEmailSelected = _.find(notifyEmailOptions, {
            value: profile.notifyEmail,
        });

        const autoSummarizeLangOptions = [
            { label: this.props.t('disabled'), value: null },
            ...config.possibleAutoSummaryLanguages.map( (langCode) => ({ label: this.props.t(langCode), value: langCode }) )
        ];

        const autoSummarizeLangSelected = _.find(autoSummarizeLangOptions, {
            value: profile.autoSumLang || null,
        });

        // translation method with fallback
        const t = this.props.t || (k => k);
        return (
            <>
            {/* <DragDropContext
                // TODO (later): move <DragDropContext/> to App.js to ensure compatibility if used in various places through the application ?
                // see: https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/drag-drop-context.md
                onBeforeCapture={() => {}}
                onBeforeDragStart={() => {}}
                onDragStart={() => {}}
                onDragUpdate={() => {}}
                onDragEnd={(d) => {
                    // TODO (later): move actions below to redux ?
                    console.log(d);
                    switch(d.type){
                        case 'CATEGORY':
                            if(d && d.source && d.destination){
                                this.updateQueryPosition(this.state.activeLang, d.source.index, d.destination.index);
                            }
                            break;
                        default: break;
                    }
                }}
            > */}
                {this.state.tryQuery &&
                    <>
                        <BackButton
                            onClick={() => {
                                this.setState({
                                    profile: {
                                        ...this.state.profile,
                                        id: this.state.tryQuery.profileID,
                                        title: this.state.profile.title || this.state.tryQuery.query,
                                        channelGroupId: this.state.tryQuery?.channelGroup?.id,
                                    },
                                    tryQuery: undefined,
                                    update: true,
                                }, () => {
                                    if (this.state.scrollTop && this.refScrollwrap) {
                                        this.refScrollwrap.scrollTop = this.state.scrollTop;
                                        this.setState({scrollTop: undefined})
                                    }
                                });
                            }}
                        />
                        <b>{this.props.t('profile_try_query')}</b> {`${this.props.t('Language')}: ${this.props.t(this.state.tryQuery.lang)}`}
                        {/* <Search
                            query={this.state.tryQuery.query}
                            searchOnMount={true}
                            channelGroup={channelGroupSelected}
                            language={this.state.tryQuery.lang === '**' ? undefined : this.state.tryQuery.lang}
                            wrapperStyle={{ height: 'calc(100% - 2.5rem)' }}
                            scopeDisabled={true}
                            historyDisabled={true}
                        /> */}
                        <MentionsVisualizer isSearch tryQuery={this.state.tryQuery} setTryQueryProfileId={this.setTryQueryProfileId}/>
                    </>
                }
                {this.state.spamCheckTotalCalls !== 0 && 
                    <LoadingIndicator
                        blockContent={true}
                        text={`${this.props.t('profile_spam_checking')} (${this.state.spamCheckFinishedCalls}/${this.state.spamCheckTotalCalls})`}
                        progress={this.state.spamCheckFinishedCalls/this.state.spamCheckTotalCalls*100}
                    />
                }
                {this.state.showDeleteProfileModal &&
                    <Popup
                        size="auto"
                        blockContent={true}
                        onCancel={() => this.setState({
                            showDeleteProfileModal: false
                        })}
                        okDisabled={true}
                        onRemove={this.deleteProfile}
                    >
                        <div className={styles.removeGroupContainer}>
                            <h3>{t('Remove Profile')}</h3>
                            <p>
                                {t('Really delete profile?')}
                            </p>
                        </div>
                    </Popup>
                }
                {this.state.showInvalidQueriesModal &&
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={()=>{ this.setState({showInvalidQueriesModal: false}) }}
                    >
                        <div className={styles.removeGroupContainer}>
                            <h3>{t('invalid_queries')}</h3>
                            <p>
                                {t('profile_invalid_queries_cant_save')}
                            </p>
                        </div>
                    </Popup>
                }
                {this.state.spamCheckOk &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={()=>{ this.setState({spamCheckOk: undefined}) }}
                    >
                        <div style={{ overflow: 'auto' }}>
                            <h2>{this.props.t('profile_spam_success_popup_title')}</h2>
                            <p>{this.props.t('profile_spam_success_popup_text')}.</p>
                        </div>
                    </Popup>
                }
                {this.state.confirmGoBack &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={() => { this.props.onGoBack(); }}
                        onCancel={() => { this.setState({ confirmGoBack: false }); }}
                    >
                        <div style={{ overflow: 'auto' }}>
                            <h2>{this.props.t('profile_unsaved_changes')}</h2>
                            
                            <p>{this.props.t('profile_unsaved_changes_text')}</p>
                        </div>
                    </Popup>
                }
                {this.state.queryWithNoLanguage &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={() => { this.setState({ queryWithNoLanguage: undefined }); }}
                    >
                        <div style={{ overflow: 'auto' }}>
                            <p>{this.props.t('queries_with_no_language')}</p>
                        </div>
                    </Popup>
                }
                {this.state.lmuStatus &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={this.state.lmuStatus === 'requested' ? ()=>{ this.setState({lmuStatus: undefined}) } : undefined}
                    >
                        <div style={{ overflow: 'auto' }}>
                            {this.state.lmuStatus === 'requested' &&
                                <>
                                    <h2>{this.props.t('profile_lmu_requested_title')}</h2>
                                    <p>{this.props.t('profile_lmu_requested_text')}.</p>
                                </>
                            }
                            {this.state.lmuStatus === 'requesting' &&
                                <>
                                    <h2>{this.props.t('profile_lmu_requesting_title')}</h2>
                                    <p>{this.props.t('profile_lmu_requesting_text')}.</p>
                                </>
                            }
                        </div>
                    </Popup>
                }
                {this.state.someSpamFailed &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={()=>{ this.setState({someSpamFailed: false}) }}
                    >
                        <div style={{overflow:'auto'}}>
                            <h2>{this.props.t('profile_spam_popup_title')}</h2>
                            <p>{this.props.t('profile_spam_popup_text')}:</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{this.props.t('Language')}</th>
                                        {this.state.profile.isSuper && <th>{this.props.t('Title')}</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(this.state.profile.queries).map((key) => {
                                        if (this.state.profile.isSuper) {
                                            return this.state.profile.queries[key]?.superQuery?.map(sq =>
                                                sq.spamCheck ? <tr><td>- {this.props.t(key)}</td><td>{sq.title}</td></tr> : undefined
                                            );
                                        } else {
                                            return this.state.profile.queries[key]?.standardQuery?.spamCheck ? <tr><td>- {this.props.t(key)}</td></tr> : undefined
                                        }
                                    })
                                }
                                </tbody>
                            </table>
                        </div>

                    </Popup>
                }
                {this.state.mixedGroup && !config.allowMixedProfileTypesInGroup &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={()=>{ this.setState({mixedGroup: undefined}) }}
                    >
                        <div style={{ overflow: 'auto' }}>
                            <h2>{this.props.t('profile_mixed_pg')}</h2>
                            <p>{this.props.t('profile_mixed_pg_text')}</p>
                            <p>{this.props.t('profile_mixed_pg_text2')}</p>
                        </div>
                    </Popup>
                }
                <div
                    ref={(r) => { this.refScrollwrap = r; }}
                    style={this.state.tryQuery && {display: 'none'}}
                    className={detailLayout.scrollWrap}
                >
                    <div style={this.state.tryQuery && {display: 'none'}} className={detailLayout.wrapper}>
                        <article className={detailLayout.view}>
                            {/* { this.state.saveFailed && this.state.saveFailed.data 
                            ? this.renderSaveFailed()
                            : null
                            } */}


                <div className={styles.backAndSaveBtns}>
                        <FormFooter
                                    onGoBack={() => {
                                        if (this.state.edited) {
                                            this.setState({ confirmGoBack: true });
                                        } else{ this.props.onGoBack(); }
                                    }}
                            // className={styles.formFooter}
                            onAction={this.saveProfile}
                            actionDisabled={this.props.sharedBy !== null || !this.state.edited || !this.state.profile.hasQuery || this.state.profile.hasDupTitles}
                            actionLabel={
                                this.state.update ? t('Save Changes') : t('Create Profile')
                            }
                            success={ this.state.saveSuccessful
                                ? t('Successfully saved') 
                                : null }
                            fail={ this.state.saveFailed
                                ? t('Could not save') 
                                : null }
                        />
                        
                        {this.props.sharedBy !== null &&
                            <InfoBox type="info" text={`${this.props.t('profile_is_shared_info')}`}></InfoBox>
                        }
                        {this.state.profile.hasDupTitles && <>
                            <InfoBox type='danger' text={`${this.props.t('profile_dup_qtitle_description')} ${this.props.t('profile_dup_qtitle_save')}`}></InfoBox>
                        </>}
                </div>
                            <Collapsible
                                collapsed={this.state.settingsCollapsed}
                                onChange={(val) => { this.setState({ settingsCollapsed: val }) }}
                                closed={t("Show Details")}
                                open={t("Hide")}
                                data-testid='settings-collapsible'
                            >
                                <div
                                    className={styles.collapsible}
                                    // style={{ height: '500px' }}
                                >
                                    <div className={styles.inputContainer}>
                                        <TextInput
                                            disabled={this.props.sharedBy !== null}
                                            className={classNames(
                                                styles.flexInput,
                                                styles.title)}
                                            value={profile.title}
                                            onChange={event => this.onTextfieldChange(event, 'title')}
                                            label={t('Title')}
                                            placeholder={t('Enter Title')}
                                            isRequired
                                            data-testid='profile-title'
                                        />

                                        <div data-testid='profile-group-dropdown-wrapper' className={[styles.flexSelect, styles.flexSelect1].join(' ')}>
                                            <LabeledSelect
                                                isDisabled={this.props.sharedBy !== null}
                                                isRequired
                                                label={t('Profile Group')}
                                                placeholder={t('Select Profile Group')}
                                                options={profileGroupOptions}
                                                value={profileGroupSelected}
                                                onChange={option => {
                                                    if(!config.allowMixedProfileTypesInGroup){
                                                        const isAllowed = this.isAllowedInGroup(
                                                            _.find(this.state.profileGroups, { id: option.value }).profiles,
                                                            this.state.profile.isSuper,
                                                            this.state.profile.id,
                                                        );
                                                        if (!isAllowed) this.setState({ mixedGroup: true });
                                                    }
                                                    this.onSelectChange(option, 'profileGroupId');
                                                }}
                                                className={classNames(
                                                    formStyles.select, 
                                                    this.props.theme.borderPrimary,
                                                    
                                                )}
                                            />
                                        </div>
                                    
                                        <div className={[styles.flexSelect,styles.flexSelect2].join(' ')}>
                                            <LabeledSelect
                                                isDisabled={this.props.sharedBy !== null}
                                                label={t('Share Groups')}
                                                placeholder={t('Select Share Groups')}
                                                options={this.availableShareGroups}
                                                value={this.mapShareGroups()}
                                                onChange={this.onShareGroupsChange}
                                                isMulti
                                                className={classNames(
                                                    formStyles.select, 
                                                    this.props.theme.borderPrimary
                                                )}
                                            />
                                        </div>
                                        <div className={[styles.flexSelect, styles.flexChannelGroup].join(' ')}>
                                            <div className={[styles.flexSelect3].join(' ')}>
                                                <LabeledSelect
                                                    isDisabled={this.props.sharedBy !== null}
                                                    isRequired
                                                    className={classNames(
                                                        formStyles.select, 
                                                        this.props.theme.borderPrimary
                                                    )}
                                                    label={t('Channel Group')}
                                                    placeholder={t('Select Channel Group')}
                                                    options={channelGroupOptions}
                                                    value={channelGroupSelected}
                                                    onChange={async (option) => {
                                                        const newState = await this.fetchLanguages(
                                                            option.value,
                                                            this.state,
                                                            (reset)=>{
                                                                if(reset){
                                                                    option={value: this.state.profile.channelGroupId};
                                                                }
                                                                this.onSelectChange(option, 'channelGroupId')
                                                            }
                                                        );

                                                        const hasQuery = this.hasQuery(newState.profile.queries);
                                                        this.setState({
                                                            channelGroupHasXX: newState.channelGroupHasXX,
                                                            profile:{...newState.profile, hasQuery},
                                                        });
                                                    }}
                                                    
                                                />
                                                {/* {this.state.channelGroupHasXX &&
                                                    <InfoBox
                                                    // show if channel group was selected which contains channels with unsupported language (lang=xx received from backend call)
                                                        type='danger'
                                                        // link='https://www.emediamonitor.net/en/support-portal/'
                                                        text={this.props.t('profile_channelGroupHasXX_title')}
                                                            // detailText={
                                                                // The selected channel group contains at least one channel which has a language that is not supported by the speech-to-text system. Creating a profile will not create any hits for these channels.
                                                                // this.props.t('profile_channelGroupHasXX_text')
                                                            // }
                                                    />
                                                } */}
                                            </div>
                                            {/* TODO: re-add below as soon as 'channels' widget is in a 'releasable state' */}
                                            {/* <div className={[styles.flexSelect,styles.channelGroupBtn,this.props.theme.borderPrimary ].join(' ')}>
                                                <Button
                                                    onClick={()=>{ 
                                                        this.setState({showChannelGroup: true });
                                                    }}
                                                    btnClass={[
                                                        this.props.theme.backgroundPrimary].join(' ')
                                                    }
                                                    disabled={this.state.profile.channelGroupId === null}>
                                                    {t('Show Group Details')}
                                                </Button>
                                            </div> */}
                                        </div>
                                    </div>   


                                    <div className={classNames(styles.rightFlexContainer)}>
                                            
                                        <LabeledSwitch
                                            disabled={this.props.sharedBy !== null || this.state.profile.isSuper}
                                            title={this.state.profile.isSuper ? this.props.t('profile_simple_profile_not_possible') : this.props.t('profile_super_cant_be_undone')}
                                            checked={this.state.profile.isSuper}
                                            onChange={() => this.onIsSuperChange()}
                                            className={[styles.rightFlexItem, styles.profileToggle].join(' ')}      
                                            labelPosition='start'
                                        > 
                                            {t('Super Profile')}
                                        </LabeledSwitch>
                                                        
                                    
                                        <LabeledSwitch
                                            disabled={this.props.sharedBy !== null}
                                            className={[styles.rightFlexItem, styles.profileToggle].join(' ')}
                                            checked={profile.isEnabled}
                                            onChange={() => this.toggleSwitch('isEnabled')}
                                            width={40}
                                            height={20}
                                            labelPosition='start'
                                            data-testid='enable-profile-switch'
                                        >
                                            {t('Enable Profile')}
                                        </LabeledSwitch>


                                        <div className={styles.notificationContainer}>
                                            <span className={styles.notifyMail}>{t('Notifications')}:</span>
                                            <div className={[styles.notifyMail, styles.emailFlex].join(' ')}>
                                                <span className={styles.notifyMail}>{t('Email')}</span>
                                                <span className={styles.rightDropdown}>
                                                <SelectAsync
                                                    isDisabled={this.props.sharedBy !== null}
                                                    loading={this.props.schedules.status === STATUS_SUCCEEDED ? false : true}
                                                    options={notifyEmailOptions}
                                                    value={notifyEmailSelected}
                                                    onChange={option => this.onSelectChange(option, 'notifyEmail')}
                                                /> </span> 
                                            </div>
                                                            
                                            <LabeledSwitch
                                                disabled={this.props.sharedBy !== null}
                                                className={styles.notifyMail}
                                                checked={profile.notifyIM}
                                                onChange={() => this.toggleSwitch('notifyIM')}
                                                labelPosition='start'
                                            >
                                                {t('Web')}
                                            </LabeledSwitch>
                                        </div> 

                                        {this.props.me?.can_AutoSummarize || profile.autoSumLang ? <div className={[styles.notifyMail, styles.emailFlex].join(' ')}>
                                            <span className={styles.notifyMail}>{t('autoSummarizeLang')}</span>
                                            <span className={styles.rightDropdown}>
                                            <SelectAsync
                                                isDisabled={this.props.sharedBy !== null || !this.props.me?.can_AutoSummarize}
                                                options={autoSummarizeLangOptions}
                                                value={autoSummarizeLangSelected}
                                                onChange={option => this.onSelectChange(option, 'autoSumLang')}
                                            /> </span> 
                                        </div> : null}

                                        <div className={classNames(styles.rightFlexItem, styles.deleteBtn)}>
                                            {this.state.update && this.props.sharedBy === null && (
                                                <>
                                                    <span>{t('Remove Profile')}</span>
                                                    <Button
                                                        type='cancel'
                                                        data-testid='remove-profile-btn'
                                                        onClick={() => {
                                                            this.setState({showDeleteProfileModal: true});
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div> 
                            </Collapsible>                             
                            <span className={styles.label}>
                                {t('Your Query in Multiple Languages')}

                                <FontAwesomeIcon color='black' className={styles.infoBtn} title={t('profile_info_shown_languages')} icon={faInfoCircle}/>
                            </span>
                            <QueryInput
                                profile={profile}
                                profileSharedBy={this.props.sharedBy}
                                isSuper={profile.isSuper}
                                queries={profile.queries}
                                showSuper={this.state.profile.isSuper}
                                onStandardQueryChange={this.onStandardQueryChange}
                                updateStandardQuery={this.updateStandardQuery}
                                addQueryGroup={this.addQueryGroup}
                                updateQueryGroup={this.updateQueryGroup}
                                updateQueryGroupType={this.updateQueryGroupType}
                                removeSuperQuery={(lang, index) => {
                                    const profile = this.removeSuperQuery(lang, index, this.state.profile);
                                    this.setState({profile});
                                }}
                                refreshTree={this.refreshTree}   
                                // queryTree={this.state.queryTree}
                                // updateQueryTree={this.updateQueryTree}
                                disableQuery={this.disableQuery}
                                onLangChange={this.onQueryLangChange}
                                removeLanguage={this.removeLanguage}
                                supportedLanguages={
                                    this.state.chGroupLangs && this.state.profile.hadXXOnLoad && !this.state.profile.isSuper
                                        ? this.state.chGroupLangs.concat(['xx'])
                                        : 
                                        this.state.chGroupLangs
                                }
                                
                                tryQuery={(tryQuery) => {
                                    this.setState({
                                        tryQuery:{
                                            lang: tryQuery.lang || '**',
                                            queries: this.state.profile.queries,
                                            query: tryQuery.query || '',
                                            isSuper: this.state.profile.isSuper,
                                            profileID: this.state.profile.id || null,
                                            id: this.state.profile.profileGroupId || null,
                                            profileTitle: this.state.profile.title,
                                            channelGroup: this.state.channelGroups.find(cg => cg.id === this.state.profile.channelGroupId) || {
                                                title: 'All Channels',
                                                channels: [],
                                                groups: [],
                                                id: null,
                                            },
                                        },
                                        scrollTop: this.refScrollwrap.scrollTop
                                    });
                                }}
                                renderSpamFailed={this.renderSpamFailed}
                                // onCurrentLangChange={(l)=>{this.setState({activeLang: l})}}
                                updateQueryPosition={this.updateQueryPosition}
                                onLmuStatusChange={(status) => { this.setState({ lmuStatus: status }) }}
                                setActiveTab={(tabIdx) => {
                                    this.setState({
                                        activeTab: tabIdx,
                                    })
                                }}
                                activeTab={this.state.activeTab}
                                selectQuery={this.selectQuery}
                                updateQueryTitleDup={(lang) => {
                                    const hasDupTitles = this.hasDupQueryTitleForLang(lang, this.state.profile.queries);
                                    this.setState(() => ({ profile: { ...profile, hasDupTitles } }));
                                }}
                                runSpamCheck={this.runSpamCheck}
                                setQueryValid={this.setQueryValid}
                                saveProfile={this.saveProfile}
                            />

                            {/* { <FormFooter
                                onGoBack={this.props.onGoBack}
                                onAction={this.saveProfile}
                                actionDisabled={!this.state.edited || !this.state.profile.hasQuery}
                                actionLabel={
                                    this.state.update ? t('Save Changes') : t('Create Profile')
                                }
                                success={ this.state.saveSuccessful
                                    ? t('Successfully saved') 
                                    : null }
                                fail={ this.state.saveFailed
                                    ? t('Could not save') 
                                    : null }
                            />} */}
                        </article>
                    </div>
                </div>
                {/* </DragDropContext> */}
            </>);
    }
}

const mapStateToProps = (state) => {
    return { 
        channelGroupReducers: state.channelGroupReducers,
        theme: state.theme,
        schedules: state.schedules,
        matomo: state.matomo,
        me: state.me,
        profileGroups: state.profileGroups,
    }
}

export default withTranslation()(connect(mapStateToProps, { getChannelGroups, getSchedules, updateProfile, deleteGroup, addProfile, deleteProfile })(Profile));
import { FailedActionTryLaterType } from '../reducers/reducers.failedActions';
import { ADD, CLEAR } from '../types/actionTypes.failedActions';

export const addFailedAction = ({ data }:FailedActionTryLaterType) => {
  return async (dispatch: any) => {
    dispatch({ type: ADD
      , data: {...data}
     });
  }
}

export const clearAllFailedActions = () =>
  ({ type: CLEAR
  })
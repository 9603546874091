import { RECEIVE_ITEMS, POST_ITEM, DELETE_ITEM } from '../types/actionTypes.epgHistory';
import _ from 'lodash';

const epgHistoryReducers = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_ITEMS:
            return action.data;
        case POST_ITEM:
            return post_item(state, action);
        case DELETE_ITEM:
            return delete_item(state, action);
        default:
            return state;
    }
};

const post_item = (state, action) => {
    let rv = _.cloneDeep(state);
    rv.push(action.data);            
    return rv;
}

const delete_item = (state, action) => {
    let rv = _.cloneDeep(state);
    const index = _.findIndex(rv, { id: action.id });
    if(index !== -1){
        rv.splice(index, 1);
    }
    return rv;
}

export default epgHistoryReducers;
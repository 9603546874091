import {
    RECEIVE_WORKSPACES, RECEIVE_WORKSPACE, ADD_WORKSPACE, DELETE_WORKSPACE,
    // RECEIVE_WIDGET,
    DELETE_WIDGET,
    SET_WIDGET_STATUS,
    // SET_LOADING
} from '../types/actionTypes.workspace';
import APISettings from 'src/API/APISettings';
import _ from 'lodash';
import { getAPISettingsType, getUniqueWidgetsSettings } from 'src/Widgets/common/helpers';
import { getAvailableWidgets } from 'src/Board/Workspace/availableWidgets';
import { SET_DEPRECATED_WIDGETS, SET_LOADING_GLOBAL } from '../types/actionTypes.board';
import APITelemetry from 'src/API/APITelemetry';
import { setTourWorkspaces } from './actions.tour';


const ensureStructur = (obj) => {
    // check for possibly empt values and remove them - we dont want to store them in backend
    // improvement TODO: should be a generic funct. in helpers.js
    if (obj.state && obj.state.settings && Object.keys(obj.state.settings).length === 0)
        delete obj.state.settings;
    if (obj.state && Object.keys(obj.state).length === 0)
        delete obj.state;
    if (obj) {
        delete obj._local;
    }
    return obj;
}

export const receivedItems = (data) => ({
    type: RECEIVE_WORKSPACES,
    data: data
});

// export const setLoading = (workspaceId, isLoading) => ({
//     type: SET_LOADING,
//     data: {workspaceId, isLoading}
// });


export const getWorkspaces = () => {
    // APISettings.getItems('widgets');
    return (dispatch) => {
        const store = window.rxStore.getState();
        const availableWidgets = getAvailableWidgets(store.me?.experimentalWidgetsSelectable, store.me?.allWidgets);

        if (store.tour.workspaces && store.tour.show) {
            // do not load tour workspaces again (if already in store)
            dispatch(receivedItems(store.tour.workspaces));
            return;
        }

        const api = getAPISettingsType();
        api.getItems('workspaces').then(async (res) => {


            let deprecated_widgets = {};
            let hasDeprecatedWidget = false;
            res.data.sort((a, b) => {   // sort by uiPosition but ensure to keep order in case of "no UI position present yet"
                const posDiff = (a.uiPosition || 0) - (b.uiPosition || 0); // Treat undefined as 0
                return posDiff || res.data.indexOf(a) - res.data.indexOf(b); // Fall back to original index
            })
            await Promise.all(res.data.map(async (workspace, workspaceIndex) => {
                
                // set uiPosition = index (if no uiPosition is set)
                if (workspace.uiPosition === undefined) {
                    workspace.uiPosition = workspaceIndex;
                    workspace.updated = true;
                }

                if(workspace.widgets){
                    workspace.widgets = await Promise.all(workspace.widgets.map(async (widgetId) => {
                        const res = await api.getItem('widgets', widgetId);
                        if (res === undefined) {
                            // APITelemetry.post({ msg: `Received widget id (${widgetId}) from GET /api/settings/workspaces/{workspaceId} but was not able to get details via GET /settings/widgets/{widgetId}` });
                            return;
                        };

                        // check if we find some saved widgets which are not available (anymore)
                        const widgetTypeExists = _.find(availableWidgets,{key:res.type});
                        if(!widgetTypeExists){
                            if(!deprecated_widgets[workspace.id]){ deprecated_widgets[workspace.id] = []; }
                            hasDeprecatedWidget = true;
                            deprecated_widgets[workspace.id].push({
                                workspaceName: workspace.name,
                                widgetName: res.name || res.type,
                                widgetId: res.dbId,
                            });
                            return;
                        }
                        res._local = {};

                        return res;
                    }));
                    workspace.widgets = workspace.widgets.filter(e => e !== undefined);
                }
            }));
            res.data = res.data.filter(e => e.widgets !== undefined);

            if(hasDeprecatedWidget){
                dispatch({
                    type: SET_DEPRECATED_WIDGETS,
                    data: deprecated_widgets
                });
            }
            const workspacesRxState = dispatch(receivedItems(res.data));

            dispatch({  // TODO: we should have separate 'loading' status for various different things in redux store, instead of one global ?
                type: SET_LOADING_GLOBAL,
                data: false
            });

            if (store.tour.show) {
                dispatch(setTourWorkspaces(workspacesRxState.data));
            }
        })
    }
};

// export const getWidget = (widgetId) => {
//     return (dispatch) => {
//         APISettings.getItem('widgets', widgetId).then((res) => {
//             dispatch({
//                 type: RECEIVE_WIDGET,
//                 data: res.data[0]
//             });
//         });
//     }
// }

export const putWidget = (widget) => {
    const api = getAPISettingsType();
    return async (dispatch) => {
        await api.putItem('widgets', widget.dbId, widget).then((res) => {
            dispatch(updateWorkspace(widget));
        });
    };
}

export const updateWorkspace = (workspace) => {
    return async (dispatch) => {
        dispatch({
            type: RECEIVE_WORKSPACE,
            data: workspace
        });
    };
}

export const putWorkspace = (workspace) => {
    const api = getAPISettingsType();
    const widgets = [..._.cloneDeep(workspace.widgets)];
    let store = window.rxStore;
    const s = store.getState();
    const storeWorkspace = _.find(s.workspaces, { id: workspace.id });
    let removedWidgets = [];
    if(storeWorkspace){
        removedWidgets = storeWorkspace.widgets.filter(item =>
            widgets.every(w => w.id !== item.id)
        );
    }

    //delete unused workspace settings (unused because no widget on this workspace uses the setting anymore)
    const availableWidgets = getAvailableWidgets(store.me?.experimentalWidgetsSelectable, store.me?.allWidgets);
    const currentAvailableSettings = getUniqueWidgetsSettings(widgets, availableWidgets);
    Object.keys(workspace.state.settings).map(currentSetting => {
        if(currentSetting === "clipsType"){
            if(!Object.keys(currentAvailableSettings).length){
                delete workspace.state.settings[currentSetting];
            } else{
                return;
            }
        }

        if (!Object.keys(currentAvailableSettings).some(
                currentAvailableSetting => currentAvailableSetting === currentSetting)
            ) {
            delete workspace.state.settings[currentSetting];
        }
        return undefined;
    });

    let workspaceBackend = _.cloneDeep(workspace);
    workspaceBackend = {
        name: workspaceBackend.name,
        widgets: workspace.widgets.reduce((sum, widget) => {
            if (widget.dbId !== undefined) {
                sum.push(widget.dbId);
            }
            return sum;
        }, []),
        state: workspaceBackend.state,
        id: workspaceBackend.id,
        uiPosition: workspaceBackend.uiPosition,
    }

    return async (dispatch) => {
        await Promise.all(widgets.map(async (widget, index) => {
            let id;
            if (widget.dbId) {
                // update existing widget in existing workspace (if they have been updated)
                if (!widget.updated) return;
                delete widget.updated;
                id = widget.dbId;
                delete widget.dbId;
                api.putItem('widgets', id, ensureStructur(widget)).then((res) => {  });
            } else {    // add new widget to existing workspace
                const { id } = (await api.postItem('widgets', ensureStructur(widget))).data;
                workspaceBackend.widgets.push(id);
                workspace.widgets[index].dbId = id;
            }
        }));

        // delete removed widgets from backend
        await Promise.all(removedWidgets.map(async (widget) => {
            if (widget.dbId) {
                await api.deleteItem('widgets', (widget.dbId));
            }
        }));

        if(workspace.updated){
            await api.putItem('workspaces', workspace.id, ensureStructur(workspaceBackend)).then((res) => { });
        }
        dispatch({
            type: RECEIVE_WORKSPACE,
            data: workspace
        });
    }
};

export const postWorkspace = (workspace) => {
    const api = getAPISettingsType();
    return async(dispatch) => {
        workspace = _.cloneDeep(workspace);
        workspace.widgets = await Promise.all(workspace.widgets.map(async (w) => {
            const { id } = (await api.postItem('widgets', ensureStructur(w))).data;
            w.dbId = id;
            return w;
        }));
        let dbWorkspace = _.cloneDeep(workspace);
        dbWorkspace.widgets = dbWorkspace.widgets.map((widget) => (widget.dbId));

        return api.postItem('workspaces', ensureStructur(dbWorkspace)).then((res) => {
            const data = { ...workspace, id: res.data.id }
            dispatch({
                type: ADD_WORKSPACE,
                data
            });
            return res;
        });
    }
};

export const deleteWorkspace = (workspace) => {
    const api = getAPISettingsType();
    return async (dispatch) => {
        await Promise.all(
            workspace.widgets.map(async (widget) => {
                if(widget.dbId){
                    await api.deleteItem('widgets', widget.dbId)
                }
            }
        ));
        api.deleteItem('workspaces', workspace.id).then(async (res) => {
            dispatch({
                type: DELETE_WORKSPACE,
                data: workspace
            });
        })
    }
};

export const deleteWidget = (widgetId) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_WIDGET,
            data: {widgetId}
        });
    }
}

export const setWidgetStatus = (workspaceId, widgetId, status) => {
    return (dispatch) => {
        dispatch({
            type: SET_WIDGET_STATUS,
            data: {workspaceId, widgetId, status}
        });
    }
}
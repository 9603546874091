 import _ from 'lodash';
import { SET_REMOVE_EPG_HISTORY_RUNNING } from '../types/actionTypes.epgHistoryState';

const epgHistoryStateReducers = (state = {
    isRemoveOldEpgHistoryRunning: false,
}, action) => {
    switch (action.type) {
        case SET_REMOVE_EPG_HISTORY_RUNNING:
            return {...state, isRemoveOldEpgHistoryRunning: action.data};
        default:
            return state;
    }
};

export default epgHistoryStateReducers;
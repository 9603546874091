import React, { Component } from 'react';
import Table from '../Table/Table';
import HitsPerDayPlot from '../Graphs/HitsPerDayPlot';
import ProfileStats from './ProfileStats';
import { withTranslation } from 'react-i18next';
import APIStatistics from '../../../API/APIStatistics';
import APISearch from 'src/API/APISearch';
import { dateToString, downloadFile } from 'src/Widgets/common/helpers';
import APIChannels from 'src/API/APIChannels';
import _ from 'lodash';
import RecordingCard from 'src/Widgets/common/RecordingCards/RecordingCard/RecordingCard';
import IFrameWrapper from 'src/Widgets/common/basicElements/IFrameWrapper/IFrameWrapper';
import { postEpgHistoryItem } from '../../../redux/actions/actions.epgHistory';
import { connect } from 'react-redux';
import BackButton from 'src/Widgets/common/basicElements/BackButton/BackButton';
import InfiniteScroll from 'src/Widgets/common/basicElements/InfiniteScroll/InfiniteScroll';
import styles from './HitsPerProfile.module.scss';
import config from 'src/config/config';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import APIReporting from 'src/API/APIReporting';
import DownloadButton from 'src/Widgets/common/basicElements/DownloadButton/DownloadButton';
import Search from 'src/Widgets/Search/Search';
import MentionsVisualizer from 'src/Widgets/MentionsVisualizer/MentionsVisualizer';

class HitsPerProfile extends Component {
    // TODO: abstract the recordingCards part some more ? things look quite similar to things that are done in MyMonitorCards.js (see also SearchResults.js)
    constructor(props) {
        super();
        this.params = props.location
            ? new URLSearchParams(props.location.search)
            : undefined;

        this.loadMore = this.loadMore.bind(this);

        this.state = {
            cards:[],
            dateFrom: undefined,
            dateTo: undefined,
            profileId: undefined,
            profileTitle: undefined,
            pageNo: 1,
            loading: undefined,
        };
    }

    async componentDidMount() {
        let channels = (await APIChannels.get()).data;
        this.setState({ channels: this.mapChannels(channels) });
    }

    mapChannels(apiChannels) {
        // TODO: move this (+ same code from Search.js to APIChannels.js) ?
        return _.map(apiChannels, (c) => ({
            id: c.id,
            icon: c.icon,
            mediaType: c.type,
            text: c.name,
            secondaryText: c.country
        }));
    }

    async loadMore(profileId, profileTitle, dateFrom, dateTo) {
        if (this.state.loading === undefined) {
            this.setState({ loading: true, showResults: true });
        }
        // const search = await APISearch.getLegacy(
        //     {
        //         dateFrom: dateFrom ? `${dateToString(dateFrom, true)}-00:00:00` : undefined,
        //         dateTo: dateTo ? `${dateToString(dateTo, true)}-23:59:59` : undefined,
        //         profileId,
        //         pageNo: this.state.pageNo
        //     },
        //     this.state.channels
        // );
        //const cardsCombined = this.state.cards.concat(search.results);
        this.setState({ loading: false });
        return ({
            //cards: cardsCombined,
            showResults: true,
            //totalFound: search.totalFound,
            dateFrom,
            dateTo,
            profileId,
            profileTitle,
            //pageNo: this.state.pageNo + 1
        });
    }

    changeIFrameUrl(url, recordingCard) {
        if (url && recordingCard) {
            this.props.postEpgHistoryItem({
                channelId: recordingCard.channelId,
                channel: recordingCard.channel,
                timestamp: recordingCard.timestamp * 1000
            });
        }
        this.setState({ iFrameUrl: url });
    }

    render() {
        // translation method with fallback
        const t = this.props.t || ((k) => k);
        const { showResults, cards, dateFrom, dateTo, profileId, profileTitle } = this.state;

        if (this.state.iFrameUrl) {
            return (
                <IFrameWrapper
                    url={this.state.iFrameUrl}
                    onGoBack={() => this.changeIFrameUrl(null)}
                    title="Media View"
                    wrapperStyle={{height:'100%'}}
                />
            );
        }

        const dateFrom_str = dateToString(dateFrom);
        const dateTo_str = dateToString(dateTo);
        let date_str = dateTo_str !== dateFrom_str
            ? `${dateFrom_str} ${t('to')} ${dateTo_str}, ${profileTitle}`
            : `${dateTo_str}, ${profileTitle}`;

        return (
            <>
                {showResults && (
                    <>
                        <div className={styles.cardsHeadWrapper}>
                            <BackButton
                                onClick={() => {
                                    this.setState({
                                        showResults: false,
                                        cards:[],
                                        date: undefined,
                                        profileId: undefined,
                                        pageNo: 1
                                    });
                                }}
                            />
                            {cards.length > 0 ?
                                <>
                                    <div>{date_str}</div>
                                    <div className={styles.numbers}>
                                        {`${t('Search Results')} (1-${cards.length} ${t('of')} ${this.state.totalFound})`}
                                    </div>
                                </>
                                : null}
                                {/* <DownloadButton
                                    type='xlsx'
                                    onClick={async () => {
                                        const xlsx = await APIReporting.getAllHitsXlsx(
                                            [profileId],
                                            { from: dateToString(dateFrom), to: dateToString(dateTo) },
                                        )
                                        downloadFile(
                                            xlsx,
                                            `eMM_profile-hits_${dateToString(dateFrom)}_to_${dateToString(dateTo)}.xlsx`,
                                            'xlsx'
                                        );
                                    }}
                                /> */}
                        </div>
                        {
                            profileTitle !== undefined && dateFrom !== undefined && dateTo !== undefined
                                ?
                                    // <Search
                                    //     query={`[${profileTitle}]`}
                                    //     dateRange={[dateFrom, dateTo]}
                                    //     searchOnMount={true}
                                    //     wrapperStyle={{height: 'calc(100% - 3.1rem)'}}
                                    // />
                                    <MentionsVisualizer
                                    externalDateRange={[dateFrom, dateTo]}
                                    externalProfileId={profileId}
                                    isRecent
                                    />
                                :
                                    null
                        }
                        {/* <InfiniteScroll 
                            className={[styles.wrapper, this.props.className].join(' ')}
                            hasMore={this.state.cards.length < this.state.totalFound}
                            loadMore={async()=>{this.setState({...await this.loadMore(profileId, profileTitle, dateFrom, dateTo )});}}
                            offset={15}
                        >
                            {
                                this.state.loading
                                    ? <LoadingSpinner />
                                    :_.map(cards, (c, idx) => (
                                        <RecordingCard
                                            cardRef={(r) => {
                                                c.cardRef = r;
                                            }}
                                            key={idx}
                                            content={c}
                                            changeIFrameUrl={(url) => {
                                                url = url.split('?');
                                                url = `${config.urls.mediaEditor}&${url[1]}`;
                                                this.changeIFrameUrl(url, c);
                                            }}
                                            htmlText={true}
                                            active={c._new}
                                        />
                                    ))}
                        </InfiniteScroll> */}
                    </>
                )}
                <ProfileStats
                    show={!showResults}
                    apiCall={APIStatistics.getProfilesCoverage}
                    history={this.props.history}
                    params={this.params}
                    saveWidgetState={this.props.saveWidgetState}
                    getWidgetState={this.props.getWidgetState}
                    workspaceId={this.props.workspaceId}
                    widgetId={this.props.widgetId}
                >
                    <Table
                        onDataClick={async(date, profileId, profileTitle)=>{
                            this.setState({...await this.loadMore(profileId, profileTitle, date, date)});
                        }}
                        onRowSumClick={async(from, to, profileId, profileTitle)=>{
                            this.setState({...await this.loadMore(profileId, profileTitle, from, to)});
                        }}
                        name={t('Table')}
                        addColumns={[
                            { title: 'Group', propName: 'group' },
                            { title: 'Profile', propName: 'title', colored: true, onClick: async(profileId, profileTitle, from, to)=>{
                                this.setState({...await this.loadMore(profileId, profileTitle, from, to)});
                            }}
                        ]}
                        xlsxDownload={true}
                    />
                    <HitsPerDayPlot series="bars" name={t('Bars')} xlsxDownload={false} />
                    <HitsPerDayPlot
                        series="lines"
                        name={t('Lines')}
                        xlsxDownload={false}
                    />
                    <HitsPerDayPlot
                        series="area"
                        stacked={true}
                        name={t('Area')}
                        xlsxDownload={false}
                    />
                </ProfileStats>
            </>
        );
    }
}

export default connect(null, { postEpgHistoryItem })(withTranslation()(HitsPerProfile));

import { FailedTryLaterPopupProps } from 'src/Widgets/common/basicElements/Banner/FailedTryLaterPopup';
import { ADD, CLEAR } from '../types/actionTypes.failedActions';

export interface FailedActionTryLaterType{
    resolution: 'TRY_LATER',
    data: FailedTryLaterPopupProps
}

interface FailedActionsReducerType{
    items: FailedActionTryLaterType[]
}

const failedActionsReducers = (state:FailedActionsReducerType = { items: [] }, action:any) => {
    let updatedState;
    switch (action.type) {
        case ADD:
            updatedState = {
                ...state,
                items: [
                    ...state.items,
                    action.data
                ]
            };
            return updatedState;
        case CLEAR:
            updatedState = { ...state, items: [] };
            return updatedState;
        default:
            return state;
    }
};

export default failedActionsReducers;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useRef, useState } from 'react';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import styles from './ClipFiltersTopBar.module.scss';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import {
    faAngleDown,
    faAngleUp,
    faEraser,
    faEye,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
//@ts-ignore
import _ from 'lodash';
import APIQueryVisualization from 'src/API/APIQueryVisualization';
import SearchBar from 'src/Widgets/common/basicElements/SearchBar/SearchBar';
import QueryVisualization from 'src/Widgets/common/QueryVisualization/QueryVisualization';

const ClipFiltersTopBar: FC<MentionsVisualizerTypes.ClipFiltersTopBarProps> = ({
    isSearch,
    isEdited,
    isRecent,
    fetchSearchClips,
    setFilter,
    //setActiveSearchHistoryItemId,
    setIsSelectChannelsShown,
    activeSearchHistoryItemId,
    setModalType,
    modalType,
    filter,
    applyFilters,
    tryQuery,
    isDateRangeSet,
    setQueryIssue,
    queryIssue,
    isQueryVizOverlayActive,
    setIsQueryVizOverlayActive,
    showQueryVis,
    setShowQueryVis
}) => {

    // check if screen size is mobile
   // const isMobileScreen = useIsScreenSize(840);

    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);

    const [queryVisualization, setQueryVisualization] = useState<any>();

    const [querySearched, setQuerySearched] = useState<boolean>(false);
    const [initialQueryApplied, setInitialQueryApplied] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isQueryValidating, setIsQueryValidating] = useState(false);

    useEffect(() => {
        if(tryQuery?.query && isSearch) {
            setFilter({
                ...filter,
                search: tryQuery.query
            });
            setInitialQueryApplied(true);
        }
    }, [tryQuery?.query]);

    useEffect(() => {
        if (isFocused && inputRef.current) {
            inputRef.current.focus();
        }
    }, [filter.search, isFocused]);

    // focus on search input when date range is set
    useEffect(() => {
        if (filter.dateRange?.startDate && filter.dateRange?.endDate && !filter.search.length && inputRef.current && isSearch) {
            inputRef.current.focus();
        }
    }, [filter.dateRange, filter.search]);

    const updateTree = async (query: string) => {
        if (!query) {
            setQueryVisualization(undefined);
            setIsQueryValidating(false);
            setQueryIssue(undefined);
            return;
        }
    
        setIsQueryValidating(true);
        setQueryIssue(t('Validating query...'));
    
        try {
            const res = await APIQueryVisualization.get(query, [90013, 1709]);
    
            if (res && res.rc === 0) {
                setQueryVisualization(res.tree);
                setQueryIssue(undefined);
            } else {
                if (res) {
                    switch (res.data.rc) {
                        case 90013:
                            setQueryIssue(t('The query syntax is invalid. Please check the query.'));
                            break;
                        case 1709:
                            setQueryIssue(t('query_char_invalid'));
                            break;
                        default:
                            break;
                    }
                }
                setQueryVisualization(undefined);
            }
        } catch (error) {
            console.error('Error during query validation:', error);
            setQueryVisualization(undefined);
            setQueryIssue(t('An unexpected error occurred. Please try again.'));
        } finally {
            setIsQueryValidating(false);
        }
    };
    

   const onQueryChange = (query: string) => {
        setFilter(
            {
                ...filter,
                search: query
            }
        )
        if(setIsQueryVizOverlayActive && showQueryVis && query){
            setIsQueryVizOverlayActive(true);
        }
        if (initialQueryApplied && query !== tryQuery?.query) {
            setInitialQueryApplied(false); // Reset when user changes the query
        }
        if (!query) {
            setQueryVisualization(undefined);
            if(setIsQueryVizOverlayActive){
                setIsQueryVizOverlayActive(false);
            }
            updateTree(query);
        }else{
            setQueryIssue(undefined);
            setQuerySearched(false);
            updateTree(query);
        }
        
    }

    //if search query has space then show query visualization
    useEffect(() => {
        if (filter.search.includes(' ') && !querySearched) {
            updateTree(filter.search);
        }else if(!filter.search.length){
            setQueryVisualization(undefined);
        }
    }, [filter.search]);

    useEffect(() => {
        if (filter.search.length > 0 && activeSearchHistoryItemId) {
            updateTree(filter.search);
        }
    }, [activeSearchHistoryItemId]);

    useEffect(() => {
        if(setIsQueryVizOverlayActive && isSearch){
            setIsQueryVizOverlayActive(showQueryVis);
        }
    }, [showQueryVis]);

    return (
        <div className={styles.filtersTopContainer}>
            <div className={styles.filterButtonsContainer}>
                {/*@ts-ignore*/}
                {/* {isSelectChannelsShown && (
                    <div
                        className={styles.backToResultsContainer}
                        onClick={() => setIsSelectChannelsShown(false)}
                    >
                        <Button
                            type="secondary"
                            btnClass={styles.backToResults}
                            onClick={() => {
                                setIsFilterVisible(true);
                                setIsSelectChannelsShown(!isSelectChannelsShown);
                            }}
                        >
                        <FontAwesomeIcon icon="arrow-left" />
                        {' '}
                        {t('Back To Results')}
                        </Button>
                    </div>
                )} */}
                {/* <Button
                    onClick={toggleFilterVisibility}
                    data-testid="cluster-showfilter-button"
                    btnClass={styles.clipFilterButton}
                    type={isFilterVisible ? 'secondary' : 'primary'}
                >
                    {isSearch ? t('Options') : isFilterVisible ? t('Close Filters') : t('Filters')}{' '}
                    {isFilterVisible ? (
                        <FontAwesomeIcon icon={isMobileScreen ? faAngleUp : faAngleUp} />
                    ) : (
                        <FontAwesomeIcon
                            icon={isMobileScreen ? faAngleDown : faAngleDown}
                        />
                    )}
                </Button> */}
                {/* {isAnyFilterSet() && (
                    <div
                        onClick={handleClearAllFilters}
                        className={styles.clipFilterClearAll}
                    >
                        <FontAwesomeIcon icon={faEraser} />
                        {t('Clear All')}
                    </div>
                )} */}
            </div>
            {isSearch && (
                <div className={styles.clipSearchInput}>
                    {(filter.search.length > 0) && (
                        <Button
                            btnClass={styles.hideQueryVisButton}
                            title={t('show_hide_query_vis')}
                            disabled={!filter.search}
                            type="primary"
                            onClick={() => {
                                setShowQueryVis(!showQueryVis);
                            }}
                        >
                            <FontAwesomeIcon
                                className={styles.hideEyeIcon}
                                icon={showQueryVis ? faEyeSlash : faEye}
                            />
                        </Button>
                    )}
                    {filter.search.length > 0 && <div className={styles.queryIssue}>{!isDateRangeSet ? 
                        <span className={styles.noDateMessage}>{t('Please select a date range to start searching')}</span> : queryIssue !== t('Validating query...') && queryIssue
                    }</div>}
                    <div className={styles.searchBarContainer}>
                    <SearchBar
                        inputRef={inputRef}
                        style={!isDateRangeSet ? {border: '1px solid red'} : {
                            border: 'none'
                        }}
                        search={onQueryChange}
                        autoComplete={!initialQueryApplied}
                        onEnter={() => {
                            if (isQueryValidating && queryIssue) {
                                return;
                            }
                            if (filter.search.length > 0) {
                                applyFilters(filter);
                                if (
                                    isSearch &&
                                    filter.search.length > 0 &&
                                    filter.dateRange?.startDate &&
                                    filter.dateRange?.endDate
                                ) {
                                    fetchSearchClips(filter.search, filter.dateRange, queryIssue);
                                    //setIsFilterVisible(false);
                                    setIsSelectChannelsShown(false);
                                    //setQueryVisualization(undefined);
                                    setShowQueryVis(false);
                                    if(setIsQueryVizOverlayActive){
                                        setIsQueryVizOverlayActive(false);
                                    }
                                }
                            }
                        }}
                        searchTermPrefill={filter.search}
                        invalid={filter.search.length > 0 && (queryIssue !== undefined && queryIssue !== t('Validating query...'))}
                        onBlur={() => {
                            setQuerySearched(true);
                        }}
                        onFocus={() => {
                            setIsFocused(true);
                            if (queryVisualization) {
                                setQuerySearched(false);
                            }
                        }}
                    />
                    </div>
                    <div className={styles.queryVisWrapper} style={
                        isQueryVizOverlayActive ? {
                            //I want an overlay to cover the whole screen
                            width: '100vw',
                            position: 'absolute',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        } : {}
                    }>
                        {queryVisualization && showQueryVis && (
                            <div>
                             {/*@ts-ignore*/}                               
                            <QueryVisualization data={queryVisualization}/>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!isSearch && !isEdited && !isRecent && (
                <Button
                    type="danger"
                    onClick={() => {
                        setModalType('saveSearchAgent');
                    }}
                    btnClass={styles.searchAgentButton}
                >
                    {modalType === 'saveSearchAgent' ? (
                        <FontAwesomeIcon icon={solidStar} />
                    ) : (
                        <FontAwesomeIcon icon={emptyStar} />
                    )}
                    {t('Agent')}
                </Button>
            )}
        </div>
    );
};

export default ClipFiltersTopBar;

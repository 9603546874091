import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import { useHistory } from 'react-router-dom';
import APISettings from 'src/API/APISettings';
import { postWorkspace } from 'src/redux/actions/actions.workspaces';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { dateToString } from 'src/Widgets/common/helpers';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';

const useGenerateClipsReport = ({
    isEdited,
    isSearch,
    selectedDateRange,
    profileGroupContent,
    activeFilter,
    selectedAnalyticsWidgets
}: MentionsVisualizerTypes.UseGenerateClipsReportProps) => {
    const workspaces = useAppSelector((state) => state.workspaces);
    const dispatch = useAppDispatch();

    const history = useHistory();
    const [generatingReport, setGeneratingReport] = useState(false);

    const { t } = useTranslation();

    const handleGenerateReport = async () => {
        setGeneratingReport(true);
        const widgetIds = await Promise.all(
            selectedAnalyticsWidgets.map(async (widget: any) => {
                let coordinatesToSend = {};
                switch (widget.key) {
                    case 'reportTitlePage':
                        coordinatesToSend = { x: 0, y: 0, w: 3, h: 1 };
                        break;
                    case 'worldMap':
                        coordinatesToSend = { x: 3, y: 6, w: 3, h: 4 };
                        break;
                    case 'sentimentBreakdown':
                        coordinatesToSend = { x: 0, y: 1, w: 3, h: 3 };
                        break;
                    case 'languageBreakdown':
                        coordinatesToSend = { x: 3, y: 0, w: 3, h: 3 };
                        break;
                    case 'mediaTypeBreakdown':
                        coordinatesToSend = { x: 0, y: 4, w: 3, h: 3 };
                        break;
                    case 'scopeBreakdown':
                        coordinatesToSend = { x: 0, y: 7, w: 3, h: 3 };
                        break;
                    case 'scopeOfMentions':
                        coordinatesToSend = { x: 3, y: 6, w: 3, h: 4 };
                        break;
                    case 'scopeOfMentionsAbsolute':
                        coordinatesToSend = { x: 0, y: 6, w: 3, h: 4 };
                        break;
                    case 'mentionsPerChannelAbsolute':
                        coordinatesToSend = { x: 3, y: 4, w: 3, h: 3 };
                        break;
                    case 'topicMentionDetailsTable':
                        coordinatesToSend = { x: 0, y: 10, w: 6, h: 3 };
                        break;
                    default:
                        coordinatesToSend = { x: 0, y: 0, w: 6, h: 6 };
                        break;
                }
                let dbWidget = {
                    type: widget.key,
                    state: {
                        settings: {},
                        //if reportTitlePage, set title
                        ...(widget.key === 'reportTitlePage' && {
                            title: isEdited
                                ? t('Edited') +
                                  ' ' +
                                  t('Clips Report') +
                                  ' ' +
                                  dateToString(new Date())
                                : t('Recent') +
                                  ' ' +
                                  t('Clips Report') +
                                  ' ' +
                                  dateToString(new Date())
                        })
                    },
                    coordinates: coordinatesToSend
                };
                const widgetData = (await APISettings.postItem('widgets', dbWidget)).data;
                return {
                    ...dbWidget,
                    id: widgetData.id
                };
            })
        );

        const calculateDaysDifference = (startDate: any | null, endDate: any) => {
            const start = new Date(startDate).setHours(0, 0, 0, 0);
            const end = new Date(endDate).setHours(0, 0, 0, 0);
            return Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
        };

        const getUniqueProfileIdsFromClips = (profileGroupContent: MentionsVisualizerTypes.ProfileGroupContent): unknown[] => {
            const uniqueProfileIds = Array.from(
              new Set(
                profileGroupContent.id === 'try-query-view' ?
                    [profileGroupContent.clips[0].profileID]
                : profileGroupContent.clips
                  .map((clip: MentionsVisualizerTypes.Clip) => clip.profileID)
                  .filter((profileID: number | string) => 
                    profileGroupContent.profiles.some((profile: MentionsVisualizerTypes.ProfileTypes) => profile.id === profileID)
                  )
              )
            );
            return uniqueProfileIds;
          };

        const newWorkspace = {
            name: isEdited
                ? t('Edited') + ' ' + t('Clips Report') + ' ' + dateToString(new Date())
                : t('Recent') + ' ' + t('Clips Report') + ' ' + dateToString(new Date()),
            widgets: widgetIds,
            uiPosition: workspaces.length +1,
            createdBy: isEdited ? 'edited' : isSearch ? 'search' : 'recent',
            state: {
                settings: {
                    profile: activeFilter.profile
                        ? [activeFilter.profile]
                        : getUniqueProfileIdsFromClips(profileGroupContent),
                    profileGroup: [profileGroupContent.id],
                    date: [dateToString(selectedDateRange.startDate), dateToString(selectedDateRange.endDate)],
                    clipsType: isEdited ? 'edited' : 'recent'
                },
                showSettings: false
            }
        };
        dispatch(postWorkspace(newWorkspace)).then((res: any) => {
            if (res.rc === 0) {
                setGeneratingReport(false);
                history.push('/workspaces/' + (workspaces.length + 1));
            }
        });
    };

    return { handleGenerateReport, generatingReport };
};

export default useGenerateClipsReport;
